export type APP = 'QUICKSIGNER' | 'ESEMNEAZA';

export type ENV = 'LOCAL' | 'DEV' | 'PROD';

export type Language = 'ro' | 'en';

export type Feature =
    | 'SUPER'
    | 'ADMIN'
    | 'API'
    | 'PHONE_RECIPIENTS'
    | 'ONE_CLICK_SIGN'
    | 'VIDEO_ID'
    | 'ADDITIONAL_FILES'
    | 'NO_BRANDING_SIGNING'
    | 'IMAGE_ONLY_SIGNATURE'
    | 'EXTRACT_TAGS'
    | 'EXTRACT_ACRO_FORM'
    | 'API_DEV';

export type Features = {
    [key in Feature]?: boolean;
};

export type CustomerType =
    | 'NEW'
    | 'ACTIVE_SUBSCRIPTION'
    | 'ACTIVE_SUBSCRIPTION_PAID_OFFLINE'
    | 'CANCELED_ACTIVE_SUBSCRIPTION'
    | 'INACTIVE';

export type Plan = 'PAID' | 'FREE' | 'PRO';

export interface User {
    id: string;
    name: string;
    email: string;
    allowedRecipientsNum: number;
    emailVerified: boolean;
    plan: Plan;
    signatureUrl: string | null;
    features: Features;
    paidUntil: string;
    customerType: CustomerType;
    maxDocumentSize: number;
    maxDocuments: number;
    apiKey?: string;
    type: RecipientType;
    numOfSeats: number | null;
    // packages?: Package[];
    // freeDaysLeft?: number;
}

export enum DnDTypes {
    SOURCE_FIELD = 'SOURCE_FIELD',
    FIELD = 'FIELD',
}

export enum FieldTypes {
    SIGNATURE = 'SIGNATURE',
    TEXT = 'TEXT',
    STAMP = 'STAMP',
    CHECKBOX = 'CHECKBOX',
    FILE = 'FILE',
}

export enum FieldMode {
    SIGNATURE = 'SIGNATURE',
    FIELD = 'FIELD',
    PREFILL = 'PREFILL',
}

export interface Field {
    id: number | string;
    type: FieldTypes;
    y: number;
    x: number;
    width: number;
    height: number;
    rawDimensions?: boolean;
    pageNum: number;
    isSelected: boolean;
    recipientIndex: number | null;
    description: string | null;
    mode: FieldMode;
    signature?: string | null;
    source?: 'api' | 'user';
    sigType?: 'image' | 'text' | 'file';
    // isFirstRender?: boolean;
    required?: boolean;
    fixed?: boolean;
}

export interface SignData {
    docUrl: string;
    fields: BackendField[];
    senderName: string;
    senderEmail: string;
    // ipAddress: string;
    signerEmail: string;
    docName: string;
    recipientId: string;
    recipientIdFull: string;
    recipientName?: string | null;
    oneClickSign?: boolean | null;
    videoId?: boolean | null;
    videoIdStatus?: VideoIdStatus | null;
    videoIdSession?: string | null;
    signedRedirectUrl?: string | null;
    declinedRedirectUrl?: string | null;
    recipientUserType: RecipientType;
    disableTextSignature?: boolean;
    noBrandingSigning?: boolean;
    hideInfoPanel?: boolean;
    englishLanguage?: boolean;
}

export type VideoIdStatus = 'PENDING' | 'FAIL' | 'SUCCESS';

type SmsStatus = 'SENDING' | 'ERROR' | 'SENT';

export interface Recipient {
    id: string;
    requestId: string;
    userId: string;
    order: number;
    sigStatus: `${SigStatus}`;
    turnStatus: `${TurnStatus}`;
    // user: User;
    fields: BackendField[];
    signedAt: string;
    name?: string | null;
    oneClickSign?: boolean | null;
    videoId?: boolean | null;
    videoIdStatus?: VideoIdStatus;
    smsStatus?: SmsStatus;
    canSendReminder?: boolean;
    type: RecipientType;
    email?: string;
    phone?: string;
    signatures?: BackendField[];
    signLink?: string;
    rejectionReason?: string;
}

export enum RecipientStatus {
    SIGNED = 'SIGNED',
    PENDING = 'PENDING',
    NEXT = 'NEXT',
    WAITING = 'WAITING',
    SIG_RECEIVED = 'SIG_RECEIVED',
    SMS_ERROR = 'SMS_ERROR',
    VIDEO_ID_ERROR = 'VIDEO_ID_ERROR',
    REJECTED = 'REJECTED',
}

export interface BackendField {
    id: string;
    type: FieldTypes;
    pageNum: number;
    recipientId: string;
    x: number;
    y: number;
    width: number;
    height: number;
    description: string | null;
    fixed?: boolean;
    url?: string;
}

export interface PdfDimensions {
    [pageNum: number]: {
        widthRatio: number;
        heightRatio: number;
        width: number;
        height: number;
    };
}

export interface FrontEndSignature {
    id?: string;
    url?: string;
    width: number;
    height: number;
    type: FieldTypes;
    pageNum: number;
    x: number;
    y: number;
    base64?: string;
}

export enum RequestStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    PROCESSING = 'PROCESSING',
    CANCELED = 'CANCELED',
    TEMPLATE = 'TEMPLATE',
    PREFILLING = 'PREFILLING',
    REJECTED = 'REJECTED',
}

export interface BackedEndPrefillText {
    id: string;
    text: string;
    height: number;
    width: number;
    pageNum: number;
    x: number;
    y: number;
}

export interface AdditionalFile {
    fileUrl: string;
    id: string;
}

export interface Request {
    id: string;
    createdAt: string;
    senderId: string;
    senderName: string;
    docUrl?: string;
    docName: string;
    signInOrder: boolean;
    message: string;
    status: `${RequestStatus}`;
    recipients?: Recipient[];
    sender?: User;
    canSign?: boolean;
    completedAt: string | null;
    tags: string[];
    preFillTexts?: BackedEndPrefillText[];
    files?: AdditionalFile[];
    copyOnlyRecipients?: { id: number; email: string }[];
}

export const RequestStatusRo = {
    [RequestStatus.COMPLETED]: 'FINALIZAT',
    [RequestStatus.IN_PROGRESS]: 'IN ASTEPTARE',
};

export interface AllDataRequest extends Request {}

export enum SigStatus {
    PENDING = 'PENDING',
    RECEIVED = 'RECEIVED',
    APPLIED = 'APPLIED',
    REJECTED = 'REJECTED',
}

export enum TurnStatus {
    WAITING = 'WAITING',
    NEXT = 'NEXT',
    COMPLETED = 'COMPLETED',
}

export const SigToRequestStatus = {
    [SigStatus.PENDING]: RequestStatus.IN_PROGRESS,
    [SigStatus.RECEIVED]: RequestStatus.IN_PROGRESS,
    [SigStatus.APPLIED]: RequestStatus.COMPLETED,
    [SigStatus.REJECTED]: RequestStatus.REJECTED,
};

export interface Dimensions {
    width: number;
    height: number;
}
export interface InitialFieldSizes {
    [FieldTypes.SIGNATURE]: Dimensions;
    [FieldTypes.TEXT]: Dimensions;
    [FieldTypes.STAMP]: Dimensions;
}

export enum Pages {
    Login = 'Login',
    Signup = 'Signup',
    ResetPassword = 'ResetPassword',
    Home = 'Home',
    EditFields = 'EditFields',
    CreateRequest = 'CreateRequest',
    SentRequests = 'SentRequests',
    ReceivedRequests = 'ReceivedRequests',
    Account = 'Account',
    Sign = 'Sign',
    SignError = 'SignError',
    SignSuccess = 'SignSuccess',
    Super = 'Super',
    Templates = 'Templates',
    CreateTemplate = 'CreateTemplate',
    EditTemplate = 'EditTemplate',
    CreateFromTemplate = 'CreateFromTemplate',
    PreFillDocument = 'PreFillDocument',
    Api = 'Api',
    BulkSent = 'BulkSent',
    BulkSendCreate = 'BulkSendCreate',
    EmailVerification = 'EmailVerification',
    CompletedDocument = 'CompletedDocument',
}

export enum SignErrorCodes {
    NotFound = 'not-found',
    InvalidSigner = 'invalid-signer',
    RequestCanceled = 'request-canceled',
    RequestCompleted = 'request-completed',
    AlreadySigned = 'already-signed',
    SignerNotNext = 'signer-not-next',
    FailedVideoId = 'failed-video-id',
    Declined = 'request-declined',
}

export interface Document {
    name: string;
    path: string;
    // downloadUrl: string;
}
export type RequestType = 'sent' | 'received' | 'template';

export type CreateRequestTypes =
    | 'newRequest'
    | 'newFromTemplate'
    | 'newTemplate'
    | 'editTemplate';

export type SubscriptionType = 'monthly' | 'annual';

export interface Package {
    id: string;
    userId: string;
    name?: string | null;
    type: 'API';
    startDate: Date | string;
    endDate: Date | string;
    numOfRequests: number;
}

export const EventType = {
    RECIPIENT_SIGNED: 'RECIPIENT_SIGNED',
    RECIPIENT_ERROR: 'RECIPIENT_ERROR',
};

export interface Webhook {
    id: string;
    userId?: string;
    url: string;
    events: string[];
}

export type RecipientType =
    | 'TEMPLATE_PLACEHOLDER'
    | 'EMAIL'
    | 'PHONE'
    | 'SIGN_LINK_ONLY';

export interface RecipientSettings {
    videoId?: boolean;
    oneClickSign?: boolean;
    isCopyOnly?: boolean;
    englishLanguage?: boolean;
    signLinkOnly?: boolean;
    sendBySMS?: boolean;
}

export type RecipientSettingsKeys = keyof RecipientSettings;

export type BulkRequestStatus = 'CREATED' | 'SENDING' | 'SENT';

export interface BulkRequest {
    id: string;
    createdAt: string;
    docName: string;
    docUrl: string;
    status: BulkRequestStatus;
    totalRequestsCount: number;
    sentRequestsCount: number;
    tags: any[];
    recipients: any[];
}

export interface AcroField {
    id: string;
    value: string;
    defaultValue: string;
    multiline: boolean;
    password: boolean;
    charLimit: number;
    comb: boolean;
    editable: boolean;
    hidden: boolean;
    name: string;
    rect: number[];
    actions: any;
    page: number;
    strokeColor: any;
    fillColor: any;
    rotation: number;
    type: string;
}

export type RequestSource = 'WEB' | 'BULK' | 'API' | 'API_DEV';

export type SeatStatus = 'INVITED' | 'COMPLETED';

export interface Seat {
    id: string;
    email: string;
    name: string;
    userId: string;
    status: SeatStatus;
}

export interface Team {
    id?: string;
    name: string;
    users: {
        id: string;
        email: string;
    }[];
}
